@import "./assets/css/swiper.min.css";

/* .qualities .swiper-wrapper {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
} */
/*
.qualities .swiper-slide {
  flex: 0 0 25%;
  max-width: 25%;
  padding: 0 10px;
} */
.disable-hover,
.disable-hover * {
  pointer-events: none !important;
}
.swiper-container__quality {
  overflow: hidden;
}
.swiper-container__quality .swiper-pagination {
  display: none;
}
.swiper-container__ship .swiper-pagination {
  z-index: initial;
}
body .statistic .fuel__type p {
  max-width: 40%;
  text-align: right;
}
.qualities .swiper-slide .dark--blue-color {
  background-color: #0f4757;
}
.qualities .swiper-slide:nth-of-type(2) .dark--blue-color {
  background-color: #b2d9e2;
}
.qualities .swiper-slide:nth-of-type(3) .dark--blue-color {
  background-color: #4d9ca3;
}
.qualities .swiper-slide:nth-of-type(4) .dark--blue-color {
  background-color: #b2d9e2;
}
#root {
  height: 100%;
}
body .loading {
  opacity: 1;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #fff;
  z-index: 12345;
}
body .loading .loading-logo {
  position: absolute;
  max-width: 150px;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
body .loading .loading-logo img {
  animation: animateLogo 2s infinite;
}
@keyframes animateLogo {
  0% {
    opacity: 0.8;
    -webkit-transform: scale(1, 1);
    -moz-transform: scale(1, 1);
    -ms-transform: scale(1, 1);
    -o-transform: scale(1, 1);
    transform: scale(1, 1);
  }
  60% {
    opacity: 1;
    -webkit-transform: scale(1.1, 1);
    -moz-transform: scale(1.1, 1);
    -ms-transform: scale(1.1, 1);
    -o-transform: scale(1.1, 1);
    transform: scale(1.1, 1);
  }
  100% {
    opacity: 0.8;
    -webkit-transform: scale(1, 1);
    -moz-transform: scale(1, 1);
    -ms-transform: scale(1, 1);
    -o-transform: scale(1, 1);
    transform: scale(1, 1);
  }
}
body {
  display: block !important;
  height: 100%;
}
* {
  text-decoration: none;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  outline: none;
  outline-color: transparent;
}
audio,
canvas,
video {
  display: inline-block;
}
audio:not([controls]) {
  display: none;
  height: 0;
}
[hidden] {
  display: none;
}
html {
  height: 100%;
  font-family: "Poppins-Regular", sans-serif;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scroll-behavior: smooth;
}
body {
  margin: 0;
}
a {
  color: #2e3c40;
}
a:focus {
  outline: none;
}
body .rellax-1-5 {
  position: relative;
  top: 80px;
}
.react-parallax {
  height: 100%;
}
.react-parallax-bgimage {
  width: 100% !important;
  height: 100% !important;
  top: 200px;
  object-fit: cover;
  object-position: center;
  opacity: 0;
  -webkit-transition: opacity 0.3s;
  -moz-transition: opacity 0.3s;
  -ms-transition: opacity 0.3s;
  -o-transition: opacity 0.3s;
  transition: opacity 0.3s;
}
.react-parallax-bgimage.animate {
  opacity: 1;
}
.react-parallax-content {
  height: 100%;
  z-index: 1;
}
a:active,
a:hover {
  outline: 0;
}
h1,
h2,
h3,
h4,
p {
  margin: 0;
}
abbr[title] {
  border-bottom: 1px dotted;
}
b,
strong {
  font-weight: bold;
}
dfn {
  font-style: italic;
}
hr {
  -moz-box-sizing: content-box;
  box-sizing: content-box;
  height: 0;
}
mark {
  background: #0f4757;
  color: #2e3c40;
}
pre {
  white-space: pre-wrap;
}
small {
  font-size: 80%;
}
figure {
  margin: 0;
}
button,
input,
select,
textarea {
  font-family: inherit;
  font-size: 100%;
  margin: 0;
}
ul,
ol {
  list-style: none;
  padding: 0;
  margin: 0;
}
button,
input {
  line-height: normal;
}
button,
select {
  text-transform: none;
}
button,
html input[type="button"],
input[type="reset"],
input[type="submit"] {
  -webkit-appearance: button;
  cursor: pointer;
}
button[disabled],
html input[disabled] {
  cursor: default;
}
input[type="checkbox"],
input[type="radio"] {
  box-sizing: border-box;
  padding: 0;
}
input[type="search"] {
  -webkit-appearance: textfield;
  -moz-box-sizing: content-box;
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
}
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}
button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0;
}
textarea {
  overflow: auto;
  vertical-align: top;
}
p {
  color: #2e3c40;
}
picture {
  display: inline-block;
}
img,
embed,
object,
video {
  display: block;
  max-width: 100%;
}
.active {
  visibility: visible;
  opacity: 1;
}
.flex {
  display: flex;
}
.flex-space-end {
  justify-content: space-between;
}
.flex-items-center {
  align-items: center;
}
.flex--end {
  align-items: flex-end;
}
.flex-items-baseline {
  align-items: baseline;
}
.block-recaptcha {
  position: relative;
  display: block;
  width: 100%;
  text-align: center;
  margin: 30px auto 0 auto;
}
.block-recaptcha > div {
  margin: 0 auto;
  display: block;
  max-width: 304px;
}
.visible-on-mobile {
  display: none !important;
}
.showMessage {
  display: block;
  width: 100%;
  text-align: center;
  margin-top: 30px;
}
.showMessage.success {
  color: #37991e;
}
.showMessage.error {
  color: #da2727;
}
.lds-ellipsis {
  width: 80px;
  height: 80px;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.lds-ellipsis div {
  position: absolute;
  top: 33px;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background: #fff;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.lds-ellipsis div:nth-child(1) {
  left: 8px;
  animation: lds-ellipsis1 0.6s infinite;
}
.lds-ellipsis div:nth-child(2) {
  left: 8px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(3) {
  left: 32px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(4) {
  left: 56px;
  animation: lds-ellipsis3 0.6s infinite;
}
@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
}
.block {
  position: relative;
  display: inline-block;
  width: 100%;
  vertical-align: top;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}
.block--padding {
  padding: 100px 0;
}
body .history__content {
  padding: 150px 0px;
}
.block--double-padding {
  padding: 150px 0;
}
.block--padding-half {
  padding: 50px 0;
}
.block--invert {
  background-color: #0e1629;
}
.block--invert .block__title {
  color: #ffffff;
}
.block--invert .block__subtitle {
  color: rgba(255, 255, 255, 0.2);
}
.block__title {
  display: block;
  max-width: 60%;
  margin: 0 auto 35px auto;
  font-family: "PublicaSans-Light", sans-serif;
  font-size: 35px;
  line-height: 40px;
  color: #000000;
}
.block--content:before {
  content: "";
  position: absolute;
  top: 50%;
  z-index: -1;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}
.block--bottom-border .container:before {
  content: "";
  position: absolute;
  width: calc(100% - 30px);
  height: 1px;
  left: 50%;
  bottom: -100px;
  background: #dedede;
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
}
.block--header-height {
  height: calc(100% - 150px);
}
.block--active.block--red-right:before {
  width: 35%;
  opacity: 1;
  transform: translateY(-50%) skew(0deg, 0deg) scale(1, 1);
}
.block--active.block--red-left:before {
  width: 40%;
  opacity: 1;
  transform: translateY(-50%) skew(0deg, 0deg) scale(1, 1);
}
.gsap-timeline {
  opacity: 0;
}
.gsap--image {
  opacity: 1;
  position: relative;
}
.df-button {
  min-width: 150px;
  display: inline-block;
  padding: 18px 40px;
  font-family: "PublicaSans-Medium", sans-serif;
  font-size: 18px;
  line-height: 1;
  text-align: center;
  border: 1px solid transparent;
  border-radius: 50px;
  background-color: #dedede;
}
.df-button--primary {
  background-color: #106bff;
  color: #fff;
  box-shadow: 0px 1px 25px rgba(16, 107, 255, 0.6);
}
.df-button--secondary {
  background-color: #232423;
  color: #fff;
}
.df-button--border {
  border: 1px solid #ffffff;
  color: #ffffff;
  background-color: transparent;
}
.absolute-center {
  position: absolute;
  width: 100%;
  top: 50%;
  left: 0;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}
.list-links {
  display: block;
}
.list-links li {
  display: block;
}
.list-links li a {
  display: block;
  font-family: "Poppins-Light", sans-serif;
  font-size: 16px;
  line-height: 28px;
  letter-spacing: 0.2px;
  margin-bottom: 5px;
  color: #0f4757;
}
.list-links li a:last-of-type {
  margin-bottom: 0;
}
.color-title {
  display: block;
  margin-bottom: 20px;
  font-family: "Poppins-Light", sans-serif;
  font-size: 18px;
  text-transform: uppercase;
  color: #0f4757;
}
.center-space {
  display: block;
  text-align: center;
  margin-top: 70px;
}
.parag {
  display: block;
  font-family: "Poppins-Light", sans-serif;
  font-size: 16px;
}
.border-left {
  border-left: 1px solid #adadad;
}
.border-right {
  border-right: 1px solid #adadad;
}
.pl-30 {
  padding-left: 30px !important;
}
.pr-30 {
  padding-right: 30px !important;
}
.pr-20 {
  padding-right: 20px !important;
}
.pt-15 {
  padding-top: 15px !important;
}
.pt-30 {
  padding-top: 30px !important;
}
.pt-50 {
  padding-top: 50px !important;
}
.pb-15 {
  padding-bottom: 15px !important;
}
.pb-0 {
  padding-bottom: 0 !important;
}
.pb-30 {
  padding-bottom: 30px !important;
}
.pb-50 {
  padding-bottom: 50px !important;
}
.extra--bottom-padding {
  padding-bottom: 150px !important;
}
.mb-30 {
  margin-bottom: 30px !important;
}
.mb-50 {
  margin-bottom: 50px !important;
}
.mb-70 {
  margin-bottom: 70px !important;
}
.mb-100 {
  margin-bottom: 100px !important;
}
.ml-10 {
  margin-left: 10px !important;
}
.mr-15 {
  margin-right: 15px !important;
}
.relative {
  position: relative;
}
.offset-top {
  top: -130px;
}
.col-border:before {
  content: "";
  position: absolute;
  width: calc(100% - 15px);
  height: 1px;
  background-color: #adadad;
  left: 15px;
  bottom: -35px;
}
.col-border:nth-of-type(2n):before {
  left: 0;
}
.grey-color {
  color: #a7a7a7;
  font-family: "Poppins-Italic", sans-serif;
}
.text-center {
  text-align: center;
}
.text-left {
  text-align: left;
}
.text-right {
  text-align: right;
}
.height {
  height: 100%;
}
.cover {
  object-fit: cover;
}
.w-70 {
  width: 70%;
}
.w-60 {
  width: 60%;
}
.half-h {
  height: 50%;
}
.half-w {
  width: 50%;
}
.full {
  width: 100%;
}
.d-inline {
  display: inline-block;
}
.right {
  float: right !important;
}
.left {
  float: left !important;
}
.input-control {
  position: relative;
  display: inline-block;
  width: 100%;
  padding: 20px 35px;
  margin-bottom: 30px;
  font-family: "PublicaSans-Light", sans-serif;
  font-size: 18px;
  border: 1px solid #dcdee6;
  border-radius: 50px;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.03);
  color: #777777;
}
.input-control::-webkit-input-placeholder {
  /* Edge */
  color: #cdd0db;
}
.input-control:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #cdd0db;
}
.input-control::placeholder {
  color: #cdd0db;
}
.input-submit {
  border: 0;
  box-shadow: 0px 0px 0px;
  background-color: #106bff;
  color: #ffffff;
  text-align: center;
}
.input-icon {
  position: relative;
  display: block;
  margin-bottom: 30px;
}
.input-icon .input-control {
  margin-bottom: 0;
}
.input-icon svg {
  width: 25px;
  position: absolute;
  right: 30px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}
textarea {
  padding: 31.5px 0;
  margin: 0;
  -webkit-appearance: none;
  appearance: none;
  resize: none;
}
.container {
  width: 100%;
  position: relative;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}
.container--padding {
  padding: 0 200px;
}
.container--work {
  padding: 0 100px;
}
.row {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}
.flex-wrap {
  flex-wrap: wrap;
}
body [class*="col-"] {
  position: relative;
  padding-right: 12px;
  padding-left: 12px;
}
.o-hidden {
  overflow: hidden !important;
}
.p-initial {
  position: initial !important;
}
.col-inner {
  display: inline-block;
  width: 100%;
  padding: 25px;
  margin-bottom: 15px;
  background-color: #dedede;
}
.col {
  -ms-flex-preferred-size: 0;
  flex-basis: 0;
  -ms-flex-positive: 1;
  flex-grow: 1;
  max-width: 100%;
}
.col-1 {
  -ms-flex: 0 0 8.333333%;
  flex: 0 0 8.333333%;
  max-width: 8.333333%;
}
.col-2 {
  -ms-flex: 0 0 16.666667%;
  flex: 0 0 16.666667%;
  max-width: 16.666667%;
}
.col-3 {
  -ms-flex: 0 0 25%;
  flex: 0 0 25%;
  max-width: 25%;
}
.col-4 {
  -ms-flex: 0 0 33.333333%;
  flex: 0 0 33.333333%;
  max-width: 33.333333%;
}
.col-5 {
  -ms-flex: 0 0 41.666667%;
  flex: 0 0 41.666667%;
  max-width: 41.666667%;
}
.col-6 {
  -ms-flex: 0 0 50%;
  flex: 0 0 50%;
  max-width: 50%;
}
.col-7 {
  -ms-flex: 0 0 58.333333%;
  flex: 0 0 58.333333%;
  max-width: 58.333333%;
}
.col-8 {
  -ms-flex: 0 0 66.666667%;
  flex: 0 0 66.666667%;
  max-width: 66.666667%;
}
.col-9 {
  -ms-flex: 0 0 75%;
  flex: 0 0 75%;
  max-width: 75%;
}
.col-10 {
  -ms-flex: 0 0 83.333333%;
  flex: 0 0 83.333333%;
  max-width: 83.333333%;
}
.col-11 {
  -ms-flex: 0 0 91.666667%;
  flex: 0 0 91.666667%;
  max-width: 91.666667%;
}
.col-12 {
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  max-width: 100%;
}
body .post {
  margin-bottom: -1px;
}
body .post__items h2 {
  max-width: 60%;
}
body .post__items p {
  max-width: 75%;
}
.statistic__right .fuel__type:last-child {
  border-bottom: none;
}
.iframe-container {
  position: relative;
  padding-top: 56.25%;
}
.iframe-map {
  position: absolute;
  top: 0;
  left: 0;
  border: 0;
}
@media (min-width: 1200px) {
  .container {
    max-width: 1140px;
  }
}
@media (min-width: 768px) {
  .col-md-12 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .col-md-11 {
    -ms-flex: 0 0 91.666667%;
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }
  .col-md-10 {
    -ms-flex: 0 0 83.333333%;
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }
  .col-md-9 {
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-md-8 {
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
  .col-md-7 {
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }
  .col-md-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-md-5 {
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }
  .col-md-4 {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .col-md-3 {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-md-2 {
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .col-md-1 {
    -ms-flex: 0 0 8.333333%;
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }
}
@media (min-width: 768px) and (max-width: 1200px) {
  .map-text {
    padding-left: 30px !important;
    padding-right: 30px !important;
  }
}
body .statistic__delivery h2 span {
  max-width: 50%;
  line-height: 75px;
}

body .post__items h2 {
  max-width: 100%;
}
body .statistic__number {
  font-size: 40px;
}
body .post__items p {
  max-width: 100%;
}
@media (min-width: 992px) {
  .col-lg-12 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .col-lg-11 {
    -ms-flex: 0 0 91.666667%;
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }
  .col-lg-10 {
    -ms-flex: 0 0 83.333333%;
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }
  .col-lg-9 {
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-lg-8 {
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
  .col-lg-7 {
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }
  .col-lg-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-lg-5 {
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }
  .col-lg-4 {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .col-lg-3 {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-lg-2 {
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .col-lg-1 {
    -ms-flex: 0 0 8.333333%;
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }
}
@media (max-width: 768px) {
  .col-sm-12 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .col-sm-11 {
    -ms-flex: 0 0 91.666667%;
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }
  .col-sm-10 {
    -ms-flex: 0 0 83.333333%;
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }
  .col-sm-9 {
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-sm-8 {
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
  .col-sm-7 {
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }
  .col-sm-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-sm-5 {
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }
  .col-sm-4 {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .col-sm-3 {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-sm-2 {
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .col-sm-1 {
    -ms-flex: 0 0 8.333333%;
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }
  .iframe {
    display: block;
    width: 90vw;
    height: 110vw;
    max-width: 800px;
    max-height: 800px;
    margin: 1em auto;
  }
}
html {
  scroll-behavior: smooth;
}
html.menu__open {
  overflow: hidden;
  height: 100%;
}
html.menu__open body {
  overflow-y: hidden;
  height: 100%;
}

body {
  height: 100%;
}
body header {
  height: 90px;
  padding: 30px 0px;
  -webkit-box-shadow: 0px 15px 18px -5px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0px 15px 18px -5px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 15px 18px -5px rgba(0, 0, 0, 0.1);
  -webkit-transition: all 0.3s cubic-bezier(0.39, 0.575, 0.565, 1);
  -moz-transition: all 0.3s cubic-bezier(0.39, 0.575, 0.565, 1);
  -o-transition: all 0.3s cubic-bezier(0.39, 0.575, 0.565, 1);
  transition: all 0.3s cubic-bezier(0.39, 0.575, 0.565, 1);
}
body header .navbar {
  height: 100%;
  display: flex;
  justify-content: space-between;
}
body header .navbar ul {
  align-self: center;
  padding-top: 2px;
}
body header .navbar ul li a {
  text-transform: uppercase;
  font-size: 14px;
  font-family: "Helvetica-Bold";
  color: #d1d1d1;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-transition: all 0.3s cubic-bezier(0.39, 0.575, 0.565, 1);
  -moz-transition: all 0.3s cubic-bezier(0.39, 0.575, 0.565, 1);
  -o-transition: all 0.3s cubic-bezier(0.39, 0.575, 0.565, 1);
  transition: all 0.3s cubic-bezier(0.39, 0.575, 0.565, 1);
}
body header .navbar ul li a:hover,
body header .navbar ul li a.active {
  color: #15708b;
}
body header .header__social__media {
  visibility: hidden;
}
body #main {
  height: 100%;
}
body #main .navbar .gsap-timeline {
  transform: translate(0px, 0px) !important;
  opacity: 1 !important;
}
body .text--uppercase {
  text-transform: uppercase;
}
body .remove--relative {
  position: initial;
}
body .remove--side-margins {
  margin-left: 0px;
  margin-right: 0px;
}
body .remove--padding-left {
  padding-left: 0px;
}
body .remove--padding-right {
  padding-right: 0px;
}
body .white--items h2,
body .white--items p {
  color: #fff;
}
body .post--application {
  background-color: #b2d9e2;
}
body .post--application_blue {
  background-color: #0f4757;
}
body .dark--blue-color {
  background-color: #0f4757;
}
body .light--blue-color {
  background-color: #b2d9e2;
}
body .blue--color {
  background-color: #4d9ca3;
}
body .align--end {
  text-align: end;
}
body h1 {
  font-family: "Helvetica-Black";
  font-size: 40px;
}
body h2 {
  font-family: "Helvetica-Black";
  font-size: 40px;
}
body h3 {
  font-family: "Helvetica-Medium";
  font-size: 38px;
  font-weight: 100;
  line-height: 50px;
  color: #0f4757;
}
body h4 {
  padding-bottom: 15px;
  text-transform: uppercase;
  font-family: "Helvetica-Light";
  font-size: 20px;
  font-weight: 100;
  letter-spacing: 4px;
  color: #d1d1d1;
}
body p {
  font-family: "Helvetica-Regular";
  font-size: 17px;
  line-height: 2;
  color: #0f4757;
}
body a {
  font-family: "Helvetica-Bold";
}
body .block {
  overflow: hidden;
}
body .block--ps {
  height: 200px;
}
body .border--mobile {
  visibility: hidden;
  opacity: 0;
}
body .main {
  height: 100%;
  z-index: 1;
}
body .main__header_holder {
  height: 90px;
  width: 100%;
}
body .main__border {
  display: flex;
  width: 100%;
  position: absolute;
  bottom: -2px;
}
body .main__border :first-child {
  background-color: #0f4757;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}
body .main__border :nth-child(2) {
  background-color: #b2d9e2;
}
body .main__border :nth-child(3) {
  background-color: #4d9ca3;
}
body .main__border :nth-child(4) {
  background-color: #b9dbe2;
}
body .main__border :nth-child(5) {
  background-color: #e7f2f4;
}
body .main__border span {
  display: inline-block;
  width: calc(100% / 5);
  height: 20px;
}
body .main .container {
  height: calc(100% - 30px);
}
body .main__info {
  position: absolute;
  bottom: 50px;
  max-width: 50%;
}
body .main__title span {
  line-height: 50px;
}
body .main__navigation {
  display: inline-block;
  height: 100%;
}
body .main__logo {
  position: relative;
  height: 100%;
  align-self: flex-end;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
body .main__image {
  background-repeat: no-repeat;
  background-size: cover;
}
body .main__image:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: -webkit-linear-gradient(
    90deg,
    #0f4757 0%,
    rgba(255, 255, 255, 0) 100%
  );
  background: -moz-linear-gradient(
    90deg,
    #0f4757 0%,
    rgba(255, 255, 255, 0) 100%
  );
  background: -ms-linear-gradient(
    90deg,
    #0f4757 0%,
    rgba(255, 255, 255, 0) 100%
  );
  background: -o-linear-gradient(
    90deg,
    #0f4757 0%,
    rgba(255, 255, 255, 0) 100%
  );
  background: linear-gradient(90deg, #0f4757 0%, rgba(255, 255, 255, 0) 100%);
  z-index: 1;
}
body .main .white--font {
  color: #fff;
}
body .main .grey--font {
  color: #d1d1d1;
}
body .remove--overlay::before {
  display: none;
}
body .history__content {
  text-align: center;
  background-color: #0f4757;
}
body .history__content p {
  display: inline-block;
  width: 75%;
  position: relative;
  text-align: center;
  line-height: 45px;
  font-size: 20px;
  color: #fff;
}
body .history--parag p {
  padding: 0px 30px 50px 30px;
}
body .history__content p span {
  font-weight: 900;
}
body .history__apps {
  padding-bottom: 100px;
}
body .history__apps p {
  padding-bottom: 30px;
}
body .history__links .row {
  justify-content: center;
}
body .history__links a {
  position: relative;
  padding: 20px 0px;
  border-radius: 30px;
  background-color: #f2f2f2;
}
body .history__links a svg {
  position: absolute;
  top: 50%;
  left: 35px;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}
body .history__links a p {
  top: initial;
  padding: 0px;
  line-height: normal;
  font-size: 17px;
  font-family: "Helvetica-Regular";
  color: #0f4757;
}
body .history__links .google__play {
  margin-right: 30px;
}
body .history__links a {
  -webkit-transition: all 0.3s cubic-bezier(0.39, 0.575, 0.565, 1);
  -moz-transition: all 0.3s cubic-bezier(0.39, 0.575, 0.565, 1);
  -o-transition: all 0.3s cubic-bezier(0.39, 0.575, 0.565, 1);
  transition: all 0.3s cubic-bezier(0.39, 0.575, 0.565, 1);
}
body .history__links a:hover {
  background-color: #4d9ca3;
}
body .history__links a:hover svg path {
  fill: #fff;
}
body .history__links a:hover p {
  color: #fff;
}
body .statistic .absolute-center {
  left: 50px;
  width: 80%;
}
body .statistic__left {
  padding: 200px 50px 200px 0px;
}
body .statistic__left h3 {
  position: relative;
  padding-bottom: 40px;
}
body .statistic .contact--color {
  background-color: #badbe2;
}
body .statistic .contact--color::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0px;
  right: 100%;
  background-color: #badbe2;
}
body .statistic .contact--text {
  color: #86a8b2;
}
body .statistic .statistic__left--supply {
  padding: 150px 50px 150px 0px;
}
body .statistic__right {
  position: absolute;
  width: 50%;
  height: 100%;
  right: 0px;
  top: 0px;
}
body .statistic .statistic__right--color {
  background-color: #e8f2f4;
}
body .statistic .statistic__right--padding {
  padding: 200px 0px 200px 50px;
}
body .statistic .statistic__right--after::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  left: 100%;
  top: 0px;
  background-color: #e8f2f4;
}
body .statistic .after--supply::after {
  background-color: #4f9ca3;
}
body .statistic .statistic__right--supply {
  background-color: #4f9ca3;
}
body .statistic .statistic__bottom--aviation {
  background-color: #b3d9e2;
}
body .statistic .statistic__bottom--aviation p {
  color: #0f4757;
}
body .statistic__top {
  position: relative;
  height: 50%;
  padding: 50px 100px;
  background-color: #e6f2f5;
}
body .statistic__top picture {
  position: absolute;
  width: 180px;
  height: 100%;
  right: 0px;
  top: 0px;
}
body .statistic__top picture img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: left;
}

body .statistic .statistic__bottom {
  position: relative;
  height: 50%;
  /* padding: 50px 100px; */
  background-color: #0f4757;
}
.military-services .statistic .statistic__bottom,
.aviation .statistic .statistic__bottom {
  position: relative;
  height: 50%;
  /* padding: 50px 100px; */
  background-color: #b3d9e2;
}
body .statistic .statistic__bottom .statistic__number,
body .statistic .statistic__bottom .statistic__text {
  color: #fff;
}
.military-services .statistic .statistic__bottom .statistic__number,
.military-services .statistic .statistic__bottom .statistic__text {
  color: #0f4757;
}
.aviation .statistic .statistic__bottom .statistic__number,
.aviation .statistic .statistic__bottom .statistic__text {
  color: #0f4757;
}
body .statistic__bottom picture {
  position: absolute;
  width: 180px;
  height: 100%;
  right: 0px;
  top: 0px;
}
body .statistic__bottom picture img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: left;
}
body .statistic__number {
  padding-bottom: 10px;
}
body .statistic__description {
  line-height: 45px;
}
body .statistic__number {
  font-family: "Helvetica-Medium";
  font-size: 40px;
  line-height: 70px;
}
body .statistic__delivery {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
body .statistic__delivery :nth-child(2) {
  opacity: 0.8;
}
body .statistic__delivery :nth-child(3) {
  opacity: 0.5;
}
body .statistic__delivery h3 {
  font-size: 22px;
  font-family: "Helvetica-Regular";
  line-height: 30px;
  color: #fff;
}
body .statistic__delivery h2 {
  font-family: "Helvetica-Medium";
  font-size: 50px;
  line-height: 65px;
  color: #fff;
}
body .statistic__delivery h2 span {
  display: inline-block;
  padding-bottom: 20px;
}

body .statistic__text {
  font-size: 18px;
  max-width: 70%;
}
body .statistic .fuel__type {
  display: flex;
  justify-content: space-between;
  padding: 30px 0px;
  border-bottom: 1px solid rgba(15, 71, 87, 0.2);
}
body .statistic .fuel__type h3,
body .statistic .fuel__type p {
  font-family: "Helvetica-Light";
}
body .statistic .fuel__type p,
body .statistic .fuel__type a {
  align-self: center;
  padding-right: 30px;
  font-size: 14px;
  line-height: 20px;
  color: #0f4757;
  opacity: 0.5;
}
body .statistic .fuel__type_contact a {
  display: block;
  font-family: "Helvetica-Light";
}
body .statistic .remove--border-bottom {
  border-bottom: none;
}
body .statistic .remove--padding-top {
  padding-top: 0px;
}
body .statistic .padding--top-10 {
  padding-top: 100px;
}
body .statistic__delivery--retail h3 {
  max-width: 40%;
  padding-bottom: 40px;
  font-size: 50px;
  line-height: 70px;
}
body .statistic__delivery--retail p {
  color: #fff;
}
body .statistic__delivery--quality p {
  font-size: 20px;
}
body .post {
  height: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
body .post:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: -webkit-linear-gradient(
    90deg,
    #0f4757 0%,
    rgba(255, 255, 255, 0) 100%
  );
  background: -moz-linear-gradient(
    90deg,
    #0f4757 0%,
    rgba(255, 255, 255, 0) 100%
  );
  background: -ms-linear-gradient(
    90deg,
    #0f4757 0%,
    rgba(255, 255, 255, 0) 100%
  );
  background: -o-linear-gradient(
    90deg,
    #0f4757 0%,
    rgba(255, 255, 255, 0) 100%
  );
  background: linear-gradient(90deg, #0f4757 0%, rgba(255, 255, 255, 0) 100%);
  z-index: 1;
}
body .post__items {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
body .post__items h2 {
  padding-bottom: 10px;
  line-height: 45px;
}

body .post__items_half {
  width: 50%;
}
body .post__items_half h2,
body .post__items_half p {
  color: #0f4757;
}
body .post__items--white h2,
body .post__items--white p {
  color: #fff;
}
body .post__button {
  padding: 20px 60px;
  font-family: "Helvetica-Bold";
  line-height: 60px;
  color: #144557;
  background-color: #fff;
  -webkit-transition: all 0.3s cubic-bezier(0.39, 0.575, 0.565, 1);
  -moz-transition: all 0.3s cubic-bezier(0.39, 0.575, 0.565, 1);
  -o-transition: all 0.3s cubic-bezier(0.39, 0.575, 0.565, 1);
  transition: all 0.3s cubic-bezier(0.39, 0.575, 0.565, 1);
}
body .post__button:hover {
  color: #fff;
  background-color: #0d718b;
}
body .post__play {
  display: flex;
  padding-top: 50px;
  background-color: transparent;
}
body .post__play p {
  padding-left: 30px;
  align-self: center;
  font-family: "Helvetica-Bold";
}
body .post__application {
  width: 60%;
  position: absolute;
  right: -100px;
  bottom: -20%;
}
body .post__application img {
  height: 100%;
  object-fit: contain;
  object-position: 0 calc(100% + 150px);
}
body .quote-image-center {
  position: absolute;
  width: 40%;
  right: 15px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
}
body .quote-image-center img {
}
body .post--application {
  height: 80%;
}
body .post--application:before {
  display: none;
}
body .qualities {
  padding: 60px 0px 120px 0px;
}
body .qualities__content {
  justify-content: space-around;
}
body .qualities__item {
  position: relative;
  min-height: 400px;
}
body .qualities__items {
  display: flex;
  flex-wrap: wrap;
  height: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  padding: 80px 0px;
  text-align: center;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
body .qualities__items p {
  width: 100%;
  font-size: 22px;
  font-family: "Helvetica-Medium";
  color: #fff;
}
body .qualities__items picture {
  width: 100%;
  height: 120px;
  align-self: end;
  padding-bottom: 30px;
}
body .qualities__items picture img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
body .qualities .remove--margin-right {
  margin-right: 0px;
}
body .qualities__title {
  padding-bottom: 80px;
  font-size: 20px;
  text-align: center;
}
body .qualities .swiper-container__quality {
  width: 100%;
}
body .app {
  background-color: #e7f2f5;
}
body .app .row--reverse {
  flex-direction: row-reverse;
}
body .app__left picture {
  height: 450px;
}
body .app__left picture img {
  height: 100%;
  object-fit: contain;
}
body .app__right {
  background-color: #fff;
}
body .app__right::after {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  left: 100%;
  top: 0;
  background-color: #fff;
}
body .app__right--reverse::after {
  content: "";
  left: initial;
  right: 100%;
}

body .app .swiper-container__app {
  text-align: center;
  overflow: hidden;
  cursor: grab;
}
body .app .swiper-container__app .swiper-slide {
  padding: 60px 0px;
}
body .app .swiper-container__app .swiper-pagination {
  bottom: 20px;
}
body
  .app
  .swiper-container__app
  .swiper-pagination
  .swiper-pagination-bullet-active {
  background-color: #0f4757;
}
body .app__text {
  padding: 0px 40px;
}
body .app__text_title {
  font-size: 22px;
  padding-bottom: 30px;
}
body .qualities--quality {
  padding: 120px 0px 90px 0px;
  background-color: #e8f2f4;
}
body .news {
  padding-bottom: 135px;
}
body .news h3 {
  padding-bottom: 60px;
}
body .news span {
  display: block;
}
body .news p {
  padding-bottom: 17px;
  font-size: 20px;
  line-height: 35px;
  color: #707070;
}
body .news .news__items--right {
  padding-right: 0px;
  padding-left: 30px;
}
body .news .news__items--left {
  padding-right: 30px;
}
body .news__content figure {
  margin-bottom: 15px;
  height: 230px;
  width: 100%;
}
body .news__content figure img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}
body .news a {
  font-size: 16px;
}
body .form {
  padding: 100px 0px;
}
body .form .row {
  justify-content: center;
}
body .form input {
  margin-bottom: 50px;
  padding-left: 0px;
  padding-right: 0px;
  padding-bottom: 15px;
  border: none;
  border-bottom: 1px solid #b2c7cd;
  line-height: 40px;
  font-family: "Helvetica-Regular";
  color: #0f4757;
}
body .form input::placeholder {
  color: #0f4757;
  line-height: 40px;
}
body .form .margin--right-3 {
  margin-right: 30px;
}
body .form .btn__send {
  display: inline-block;
  position: relative;
  width: 200px;
  padding: 20px 0px;
  margin-top: 20px;
  border: none;
  font-family: "Helvetica-Regular";
  background-color: #0f4757;
  color: #fff;
  -webkit-transition: all 0.3s cubic-bezier(0.39, 0.575, 0.565, 1);
  -moz-transition: all 0.3s cubic-bezier(0.39, 0.575, 0.565, 1);
  -o-transition: all 0.3s cubic-bezier(0.39, 0.575, 0.565, 1);
  transition: all 0.3s cubic-bezier(0.39, 0.575, 0.565, 1);
}
body .social__icons svg:hover path {
  fill: #0d718b;
}
body .social__icons svg path {
  -webkit-transition: all 0.3s cubic-bezier(0.39, 0.575, 0.565, 1);
  -moz-transition: all 0.3s cubic-bezier(0.39, 0.575, 0.565, 1);
  -o-transition: all 0.3s cubic-bezier(0.39, 0.575, 0.565, 1);
  transition: all 0.3s cubic-bezier(0.39, 0.575, 0.565, 1);
}
body .form .btn__send:hover {
  background-color: #0d718b;
}
body .ship {
  padding: 100px 0px 60px 0px;
}
body .ship p {
  padding-bottom: 60px;
  line-height: 45px;
  font-size: 20px;
  text-align: center;
}
body .ship .swiper-container__ship {
  position: relative;
  margin-bottom: 50px;
  overflow: hidden;
}
body .ship .swiper-pagination-bullet-active {
  background-color: #539ca3;
}
body .exluded--title {
  font-family: "Helvetica-Medium";
  font-size: 30px;
  font-weight: 100;
  line-height: 40px;
  color: #0f4757;
}
body .requirements {
  padding: 150px 0px;
  background-color: #e8f2f5;
}
body .requirements__title {
  padding-bottom: 100px;
  font-size: 20px;
  text-align: center;
}
body .requirements h3 {
  display: inline-block;
  font-family: "Helvetica-Light";
  font-size: 20px;
}
body .requirements svg {
  align-self: center;
  cursor: pointer;
}
body .requirements__item {
  display: flex;
  justify-content: space-between;
  padding: 10px 0px;
  border-bottom: 1px solid #12465718;
}
body .requirements__items {
  justify-content: space-between;
  margin-bottom: 10px;
}
body .requirements__tooltip {
  align-self: center;
}
body .requirements__tooltip .tooltip__item {
  width: 90%;
  position: absolute;
  right: 10px;
  top: 65px;
  padding: 20px 60px 20px 20px;
  visibility: hidden;
  opacity: 0;
  border-radius: 5px;
  background-color: white;
  z-index: 1;
  -webkit-transition: all 0.5s cubic-bezier(0.39, 0.575, 0.565, 1);
  -moz-transition: all 0.5s cubic-bezier(0.39, 0.575, 0.565, 1);
  -o-transition: all 0.5s cubic-bezier(0.39, 0.575, 0.565, 1);
  transition: all 0.5s cubic-bezier(0.39, 0.575, 0.565, 1);
}
body .requirements__tooltip .tooltip__item::after {
  content: "";
  width: 0;
  height: 0;
  position: absolute;
  top: -10px;
  right: 10px;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid white;
}
body .requirements__tooltip .tooltip__item p {
  font-size: 13px;
  font-family: "Helvetica-Light";
  line-height: 25px;
  opacity: 0.5;
}
body .requirements__tooltip .tooltip__visible {
  visibility: visible;
  opacity: 1;
}
body .requirements__tooltip svg:hover + .tooltip__item {
  visibility: visible;
  opacity: 1;
}
body .half__left {
  position: absolute;
  left: 0px;
  width: 50%;
  height: 100%;
  background-repeat: no-repeat;
  background-size: cover;
}
body .half__right {
  float: right;
  width: 50%;
  padding: 200px 0px 200px 100px;
}
body .half__right p {
  font-size: 20px;
}
body .expertise {
  background-color: #e7f2f5;
}
body .expertise__right {
  position: absolute;
  right: 0px;
  width: 50%;
  height: 100%;
}
body .expertise__right picture {
  width: 100%;
  height: 100%;
}
body .expertise__right picture img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
body .expertise__left {
  float: left;
  width: 50%;
  padding: 200px 100px 200px 0px;
}
body .expertise__left p {
  font-size: 20px;
}
body .expertise__right--military {
  right: initial;
  left: 0px;
}
body .expertise__left--military {
  float: right;
  padding: 200px 0px 200px 100px;
}
body .expertise--military {
  background-color: #e6f2f5;
}
body .services {
  padding: 100px 0px 80px 0px;
}
body .services span {
  display: block;
}
body .services p {
  font-size: 22px;
}
body .services__text {
  padding-bottom: 150px;
}
body .services__item {
  text-align: center;
}
body .services__item picture {
  height: 120px;
}
body .services__item p {
  padding: 20px 0px;
  font-family: "Helvetica-Medium";
  color: #b4d9e2;
}
body .services__description {
  text-align: center;
  font-size: 20px;
  font-family: "Helvetica-Regular";
  color: #0f4757;
}
body .services__picture_padding img {
  padding-top: 10px;
}
body .locations {
  overflow-x: hidden;
  background-color: #519ca3;
}
body .locations__left {
  padding: 50px 100px 60px 0px;
}
body .locations__left p {
  color: #fff;
}
body .locations__right {
  padding: 50px 100px 60px 60px;
  background-color: #e7f2f5;
}
body .locations__right::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 100%;
  background-color: #e7f2f5;
}
body .read {
  padding: 150px 0px;
}
body .read__content {
  text-align: center;
}
body .read__title {
  padding-bottom: 50px;
  font-size: 20px;
}
body .read__text-content {
  display: block;
  overflow: hidden;
  height: 300px;
}
body .read__text {
  display: block;
  position: relative;
  margin-bottom: 30px;
}
body .read__overlay::after {
  content: "";
  width: 100%;
  height: 30%;
  position: absolute;
  bottom: 0px;
  right: 0px;
  background: -webkit-linear-gradient(
    180deg,
    rgba(255, 255, 255, 0.38) 38%,
    white 100%
  );
  background: -moz-linear-gradient(
    180deg,
    rgba(255, 255, 255, 0.38) 38%,
    white 100%
  );
  background: -ms-linear-gradient(
    180deg,
    rgba(255, 255, 255, 0.38) 38%,
    white 100%
  );
  background: -o-linear-gradient(
    180deg,
    rgba(255, 255, 255, 0.38) 38%,
    white 100%
  );
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0.38) 38%,
    white 100%
  );
}
body .read__button {
  display: block;
  position: relative;
  padding-right: 30px;
  border: none;
  font-family: "Helvetica-Bold";
  color: #0f4757;
  background-color: transparent;
  z-index: 1;
}
body .read__button_content {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
  cursor: pointer;
}
body .read__button_content picture {
  position: relative;
  z-index: 1;
}
body .footer {
  overflow: hidden;
}
body .footer .language__switcher a {
  display: inline-block;
  color: #b2c7cd;
  font-size: 14px;
  margin-right: 5px;
}
body .footer a {
  font-family: "Helvetica-Regular";
  color: #fff;
}
body .footer .social {
  background-color: #0f4757;
  padding: 60px 0px;
}
body .footer .social ul li {
  display: inline-block;
}
body .footer .social__media {
  text-align: end;
}
body .footer .social__icons {
  justify-content: flex-end;
}
body .footer .social__icons li {
  padding-left: 20px;
}
body .footer .social__links li {
  padding-right: 60px;
}
body .footer .social__links li a {
  -webkit-transition: all 0.3s cubic-bezier(0.39, 0.575, 0.565, 1);
  -moz-transition: all 0.3s cubic-bezier(0.39, 0.575, 0.565, 1);
  -o-transition: all 0.3s cubic-bezier(0.39, 0.575, 0.565, 1);
  transition: all 0.3s cubic-bezier(0.39, 0.575, 0.565, 1);
}
body .footer .social__links li a:hover {
  color: #b2c7cd;
}
body .footer .social__links .language__switcher a:hover,
body .footer .social__links .language__switcher a.active {
  color: #fff;
}
body .footer .copyright {
  padding: 60px 0px;
}
body .footer .copyright__text {
  text-align: end;
}
body .footer .copyright__text p {
  font-size: 16px;
}
.main__logo::after {
  width: 100%;
  height: 100%;
  font-family: "Helvetica-Bold";
  font-size: 32px;
  line-height: 1px;
  text-transform: uppercase;
  color: #d1d1d1;
}
.trading .main__logo::after {
  content: "TRADING";
}
.supply .main__logo::after {
  content: "SUPPLY";
}
.sqtregetimi .main__logo::after {
  content: "TREGËTIMI";
}
.sqfurnizimi .main__logo::after {
  content: "FURNIZIMI";
}
.sqaviacion .main__logo::after {
  content: "AVIACION";
}
.sqsherbimet-ushtarake .main__logo::after {
  content: "SHËRBIMET USHTARAKE";
}
.sqretail-al .main__logo::after {
  content: "RETAIL";
}
.sqkualiteti .main__logo::after {
  content: "KUALITETI";
}
.aviation .main__logo::after {
  content: "AVIATION";
}
.retail .main__logo::after {
  content: "RETAIL";
}
.military-services .main__logo::after {
  content: "military services";
}
.quality .main__logo::after {
  content: "safety & quality";
}
.military-services .main__logo::after,
.quality .main__logo::after,
.sqsherbimet-ushtarake .main__logo::after {
  position: absolute;
  padding-left: 3px;
  font-size: 15px;
  line-height: 14px;
  letter-spacing: 1px;
}
.responsive__menu {
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
  -webkit-transition: all 0.3s cubic-bezier(0.39, 0.575, 0.565, 1);
  -moz-transition: all 0.3s cubic-bezier(0.39, 0.575, 0.565, 1);
  -o-transition: all 0.3s cubic-bezier(0.39, 0.575, 0.565, 1);
  transition: all 0.3s cubic-bezier(0.39, 0.575, 0.565, 1);
  transition-delay: 0.8s;
}
.main__header {
  display: flex;
  width: 100%;
  position: absolute;
  padding-top: 30px;
  visibility: visible;
  opacity: 1;
  background-color: #fff;
  z-index: 99;
  -webkit-transition: ease-out 0.5s cubic-bezier(0.39, 0.575, 0.565, 1);
  -moz-transition: ease-out 0.5s cubic-bezier(0.39, 0.575, 0.565, 1);
  -o-transition: ease-out 0.5s cubic-bezier(0.39, 0.575, 0.565, 1);
  transition: ease-out 0.5s cubic-bezier(0.39, 0.575, 0.565, 1);
  transition-delay: 0.2s;
}
.header__close {
  display: inline-block;
  position: relative;
  width: 100%;
  padding-top: 30px;
  visibility: hidden;
  opacity: 0;
}
.menu li {
  display: inline-block;
  margin-left: 40px;
}
.menu .current-menu-item a {
  color: #0d718b;
}
.block--active .animate--title span {
  transform: translateY(0) rotate(0deg);
  opacity: 1;
  -webkit-clip-path: polygon(100% 0, 100% 100%, 0 100%, 0 0);
  clip-path: polygon(100% 0, 100% 100%, 0 100%, 0 0);
}
.block--out .animate--title span:first-child {
  transition-delay: 0.2s;
}
.block--out .animate--title span:last-child {
  transition-delay: 0.1s;
}
.animate--title span {
  display: block;
  margin-right: 10px;
  -webkit-clip-path: polygon(100% 100%, 100% 100%, 0 100%, 0 100%);
  clip-path: polygon(100% 50%, 100% 100%, 0 100%, 0 50%);
  transform: translateY(-20px) rotate(-1deg);
  opacity: 0;
  -webkit-transition: all 0.7s cubic-bezier(0.39, 0.575, 0.565, 1);
  -moz-transition: all 0.7s cubic-bezier(0.39, 0.575, 0.565, 1);
  -o-transition: all 0.7s cubic-bezier(0.39, 0.575, 0.565, 1);
  transition: all 0.7s cubic-bezier(0.39, 0.575, 0.565, 1);
}
.animate--title span:first-child {
  transition-delay: 0.6s;
}
.animate--title span:last-child {
  transition-delay: 0.4s;
}
.hover-reveal {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  pointer-events: none;
  opacity: 0;
}
.hover-reveal__inner,
.hover-reveal__img {
  width: 100%;
  height: 100%;
  position: relative;
  background-color: rgba(31, 31, 31, 0.281);
}
.hover-reveal__deco {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(178, 217, 226, 0.1);
}
.hover-reveal__img {
  background-size: cover;
  background-position: 50% 50%;
}
@media (max-width: 1200px) {
  .main__logo::after {
    display: none;
  }
}
@media (max-width: 992px) {
  body .animate--title span {
    display: block;
  }
  body header .navbar ul li a {
    font-size: 13px;
  }
  body header .navbar ul li {
    margin-left: 20px;
  }
  body .section--padding {
    padding: 100px 15px;
  }
  body .block--padding {
    padding: 100px 0px;
  }
  body .statistic .container {
    padding-left: 0px;
    padding-right: 0px;
  }
  body .statistic__left {
    padding: 200px 50px 200px 15px;
  }
  body .statistic .statistic__left--supply {
    padding: 100px 15px;
  }
  body .statistic__right {
    width: 100%;
    position: initial;
  }
  body .statistic .absolute-center {
    position: initial;
    top: initial;
    left: initial;
    -webkit-transform: translateY(0px);
    -moz-transform: translateY(0px);
    -ms-transform: translateY(0px);
    transform: translateY(0px);
    width: 100%;
  }
  body .statistic__top picture {
    right: -30px;
  }

  body .statistic__bottom picture {
    right: -30px;
  }

  body .statistic__delivery {
    position: initial;
    -webkit-transform: translateY(0px);
    -moz-transform: translateY(0px);
    -ms-transform: translateY(0px);
    transform: translateY(0px);
  }
  body .statistic__delivery h2 span {
    display: block;
  }
  body .statistic .statistic__right--padding {
    padding: 100px 15px;
  }
  body .form input {
    margin-right: 0px;
  }
  body .form .margin--right-3 {
    margin-right: 0px;
  }
  body .qualities__items p {
    margin-right: 0px;
  }
  body .post__items {
    padding-right: 30px;
  }
  body .post__items h2 {
    padding-bottom: 20px;
  }
  body .news {
    padding-bottom: 100px;
  }
  body .news__items {
    margin-bottom: 70px;
  }
  body .news .news__items--right {
    position: initial;
    padding-left: 30px;
    padding-right: 0px;
  }
  body .news__content {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
  }
  body .news__content a {
    width: 100%;
  }
  body .requirements__title {
    text-align: left;
  }
  body .newsletter .position--absolute {
    position: relative;
  }
  body .newsletter__content {
    padding: 50px 0px 70px 0px;
  }
  body .newsletter__text {
    padding-bottom: 70px;
  }
  body .half .container {
    padding-left: 0px;
    padding-right: 0px;
  }
  body .half__left {
    position: relative;
    width: 100%;
    height: 50vh;
    background-position: center;
  }
  body .half__right {
    width: 100%;
  }
  body .expertise .container {
    padding-left: 0px;
    padding-right: 0px;
  }
  body .expertise__left {
    width: 100%;
    float: initial;
  }
  body .expertise__right {
    position: initial;
    width: 100%;
    height: 60vh;
  }
  body .expertise__right img {
    object-position: top;
  }
  body .expertise__left--military {
    padding: 100px 15px;
  }
  body .post__application {
    bottom: 0;
  }
  body .services {
    padding: 100px 0px 90px 0px;
  }
  body .services__text {
    padding-bottom: 100px;
  }
  body .services__item p {
    padding-top: 0px;
  }
  body .locations .container {
    padding-left: 0px;
    padding-right: 0px;
  }
  body .history__links {
    padding: 0px 15px;
  }
  body .history__links .google__play {
    margin: 0px 0px 30px 0px;
  }
  body .app .absolute-center {
    position: initial;
    top: initial;
    left: initial;
    padding: 50px 15px;
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
  }
  body .footer .social {
    text-align: center;
  }
  body .footer .social ul li {
    display: inline-block;
    padding-right: 30px;
  }
  body .footer .social .row {
    justify-content: center;
  }
  body .footer .social__icons a {
    padding-right: 30px;
  }
  body .footer .social__top {
    margin-bottom: 30px;
  }
  body .footer .copyright {
    padding: 20px 0px;
  }
  body .footer .copyright .row {
    justify-content: center;
  }
  body .footer .copyright__text {
    text-align: center;
  }
  body .footer .copyright__logo {
    margin-bottom: 20px;
  }
  .navbar ul .menu li {
    display: inline-block;
    margin-left: 20px;
  }
  .navbar ul .menu li a {
    font-size: 12px;
  }
  .map-desc {
    text-align: center !important;
  }
}
@media (max-width: 767px) {
  .border--mobile {
    visibility: hidden;
    opacity: 0;
  }
  body .main__info {
    max-width: 100%;
  }
  .menu__open .border--mobile {
    width: 100%;
    bottom: -30px;
    left: 0px;
    visibility: visible;
    opacity: 1;
    z-index: 9;
  }
  body .statistic__text {
    max-width: 100%;
  }
  body h1 {
    font-size: 30px;
  }
  body h2 {
    font-size: 28px;
  }
  body h3 {
    font-size: 26px;
    line-height: 40px;
  }
  body h4 {
    font-size: 17px;
    padding-bottom: 5px;
  }
  body p {
    font-size: 15px;
  }
  body .main__header {
    box-shadow: 2px 7px 10px -5px rgba(0, 0, 0, 0.1);
    -webkit-box-shadow: 2px 7px 10px -5px rgba(0, 0, 0, 0.1);
  }
  body .main__navigation {
    display: flex;
    height: calc(100% - 50px);
  }
  body .swiper-container__quality .swiper-pagination {
    display: inline-block;
    bottom: -35px;
  }
  body .main__title span {
    line-height: 40px;
  }
  body .navbar ul .menu li {
    margin-left: 0px;
  }
  body .news__items {
    margin-bottom: 0px;
  }
  .quality .main__image {
    background-position: 70%;
  }
  .retail .main__image {
    background-position: 75%;
  }
  .military-services .main__image,
  .military-services .main__image {
    background-position: 90%;
  }
  .supply .main__image {
    background-position: 35%;
  }
  .trading .main__image {
    background-position: 25%;
  }
  body .news .news__items--left {
    padding-right: 0px;
    margin-bottom: 80px;
  }
  body .news .news__items--right {
    padding-left: 0px;
  }
  body .post__items h2 {
    line-height: 35px;
  }
  body .post__items_half {
    width: 100%;
    position: relative;
    top: initial;
    padding: 30px 0px;
    z-index: 9;
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
  }
  body .post__items {
    max-width: 100%;
  }
  body .post__application {
    width: 100%;
    bottom: -35%;
    right: initial;
  }
  body .quote-image-center {
    width: 90%;
    left: 20px;
    top: 80%;
    -webkit-transform: translateY(-80%);
    -moz-transform: translateY(-80%);
    -ms-transform: translateY(-80%);
    transform: translateY(-80%);
  }
  body .services .remove--margin-bottom {
    margin-bottom: 0px;
  }
  body .qualities__items picture {
    height: 90px;
  }
  body .qualities__items p {
    font-size: 18px;
  }
  body .requirements__tooltip .tooltip__item::after {
    top: 10px;
    right: -13px;
    -webkit-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    transform: rotate(90deg);
  }
  body .menu li {
    opacity: 0;
    -webkit-transition: cubic-bezier(0.4, 0.01, 0.165, 0.99) 0.7s
      cubic-bezier(0.39, 0.575, 0.565, 1);
    -moz-transition: cubic-bezier(0.4, 0.01, 0.165, 0.99) 0.7s
      cubic-bezier(0.39, 0.575, 0.565, 1);
    -o-transition: cubic-bezier(0.4, 0.01, 0.165, 0.99) 0.7s
      cubic-bezier(0.39, 0.575, 0.565, 1);
    transition: cubic-bezier(0.4, 0.01, 0.165, 0.99) 0.7s
      cubic-bezier(0.39, 0.575, 0.565, 1);
    transition: opacity 0.5s cubic-bezier(0.4, 0.01, 0.165, 0.99);
  }
  body .menu li:nth-child(1) {
    transition-delay: 0.39s;
  }
  body .menu li:nth-child(2) {
    transition-delay: 0.48s;
  }
  body .menu li:nth-child(3) {
    transition-delay: 0.57s;
  }
  body .menu li:nth-child(4) {
    transition-delay: 0.66s;
  }
  body .menu li:nth-child(5) {
    transition-delay: 0.75s;
  }
  body .menu li:nth-child(6) {
    transition-delay: 0.84s;
  }
  body .menu li:nth-child(7) {
    transition-delay: 0.93s;
  }
  .menu__open .main__navigation li a {
    color: #d1d1d1;
  }
  .menu__open ul .current-menu-item a {
    color: #0d718b;
  }
  .menu__open .main__header {
    height: 100%;
    background-color: #fff;
    transition-delay: 0s;
  }
  .menu__open .main__header ul li a {
    font-family: "Helvetica-Black";
    font-size: 25px;
    text-transform: initial;
  }
  .menu__open .responsive__menu {
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  }
  .menu__open .responsive__menu .line:first-child {
    margin-top: 11px;
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
  .menu__open .responsive__menu .line:nth-child(2) {
    opacity: 0;
  }
  .menu__open .responsive__menu .line:last-child {
    margin-top: -16px;
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    transform: rotate(-45deg);
  }
  .main__navigation {
    position: absolute;
    display: none;
    align-items: center;
    justify-content: initial;
    width: 100%;
    height: 0;
    top: 85px;
    left: 0px;
    text-align: center;
    visibility: hidden;
    opacity: 0;
    background-color: #fff;
    z-index: 9;
    -webkit-transition: all 0.7s cubic-bezier(0.39, 0.575, 0.565, 1);
    -moz-transition: all 0.7s cubic-bezier(0.39, 0.575, 0.565, 1);
    -o-transition: all 0.7s cubic-bezier(0.39, 0.575, 0.565, 1);
    transition: all 0.7s cubic-bezier(0.39, 0.575, 0.565, 1);
  }
  .main__navigation ul {
    margin-bottom: 85px;
    opacity: 0;
  }
  .main__navigation.visible__navigation {
    padding: 0px 15px;
  }
  .main__navigation.visible__navigation ul {
    opacity: 1;
  }
  .main__navigation .header__social__media {
    display: block;
    position: absolute;
    bottom: 40px;
    margin-bottom: 30px;
  }
  .main__navigation .header__social__media ul {
    display: flex;
    align-items: center;
    margin-bottom: 0;
  }
  .main__navigation .header__social__media li {
    font-size: 18px;
    text-decoration: underline;
  }
  .main__navigation .header__social__media li:not(:last-child) {
    padding-bottom: 0;
    margin-right: 30px;
  }
  .main__navigation .header__social__media .facebook__logo {
    width: 15px;
  }
  .main__navigation .header__social__media .twitter__logo {
    width: 30px;
  }
  .main__navigation .header__social__media .instagram__logo {
    width: 25px;
  }
  .main__navigation .header__social__media .linkedin__logo {
    width: 20px;
  }
  .main__navigation .menu-item-has-children {
    display: flex;
    justify-content: center;
    padding: 0;
    background-image: none;
  }
  .main__navigation .menu-item-has-children a {
    margin: 0 5px;
  }
  .main__navigation .sub-menu {
    position: relative;
    top: 0;
    left: 0;
    min-width: auto;
    padding: 0;
    box-shadow: none;
    background-color: transparent;
  }
  .main__navigation li {
    display: block;
    width: 100%;
    margin-left: 0px;
    font-size: 38px;
    text-align: left;
    visibility: hidden;
    opacity: 0;
  }
  .main__navigation li:not(:last-child) {
    padding-bottom: 5px;
  }
  .main__navigation li.current_page_item a {
    font-weight: bold;
  }
  .main__navigation li.current-page-ancestor a {
    font-weight: bold;
  }
  .visible__navigation {
    display: flex;
    visibility: visible;
    opacity: 1;
  }
  body .visible__navigation li {
    visibility: visible;
    opacity: 1;
  }
  .responsive__menu {
    display: block;
    float: right;
    opacity: 1;
    z-index: 9;
    cursor: pointer;
  }
  .line {
    width: 30px;
    height: 3px;
    margin: 5px 0;
    background-color: #0d718b;
    -webkit-transition: all 0.3s cubic-bezier(0.39, 0.575, 0.565, 1);
    -moz-transition: all 0.3s cubic-bezier(0.39, 0.575, 0.565, 1);
    -o-transition: all 0.3s cubic-bezier(0.39, 0.575, 0.565, 1);
    transition: all 0.3s cubic-bezier(0.39, 0.575, 0.565, 1);
  }
  body .services__item {
    margin-bottom: 50px;
  }
  .visible-on-mobile {
    display: block !important;
  }
  /* body .post {
    height: 80%;
  } */
  body .post__button {
    padding: 15px 20px;
    font-size: 14px;
  }
  /* body .main {
    height: 100%;
  } */
}
@media (max-width: 576px) {
  body {
    overflow-x: hidden;
  }
  body .section--padding {
    padding: 50px 15px;
  }
  body .block--padding {
    padding: 50px 0px;
  }
  body .remove--padding-left {
    padding-left: 0px;
    padding-right: 0px;
  }
  body .main__title {
    max-width: 100%;
  }
  body .history__content p {
    top: initial;
    font-size: 17px;
  }
  body .history__apps p {
    padding: 130px 0px 50px;
  }
  body .post__application {
    bottom: -20%;
  }
  body .statistic__left {
    padding: 130px 15px;
  }
  body .statistic__left h3 {
    top: initial;
  }
  body .swiper-container__quality .swiper-pagination-bullet-active {
    background-color: #0f4757;
  }
  body .statistic__description {
    font-size: 15px;
    line-height: 30px;
  }
  body .statistic__text {
    font-size: 15px;
  }
  body .statistic__delivery h3 {
    font-size: 18px;
  }
  body .statistic__delivery h2 {
    font-size: 35px;
  }
  body .statistic__delivery h2 span {
    padding-bottom: 20px;
  }
  body .statistic__delivery--retail h3 {
    font-size: 35px;
    line-height: 55px;
  }
  body .statistic__delivery--quality p {
    font-size: 15px;
    line-height: 30px;
  }
  body .statistic .statistic__left--supply {
    padding: 130px 15px;
  }
  body .statistic .statistic__right--padding {
    padding: 50px 15px;
  }
  body .news {
    padding-bottom: 80px;
  }
  body .news p {
    font-size: 15px;
    line-height: 25px;
  }
  body .news a {
    font-size: 14px;
  }
  body .newsletter .position--absolute {
    position: relative;
  }
  body .newsletter__content {
    padding: 50px 0px 70px 0px;
  }
  body .newsletter__text {
    padding-bottom: 70px;
    line-height: 25px;
    font-size: 15px;
  }
  body .ship p {
    font-size: 17px;
    line-height: 30px;
  }
  body .requirements__title {
    padding-bottom: 50px;
    font-size: 17px;
  }
  body .requirements__items {
    margin-bottom: 0px;
  }
  body .requirements__tooltip .tooltip__item {
    top: initial;
    bottom: -40px;
    right: 35px;
  }
  body .half__right p {
    font-size: 17px;
  }
  body .expertise__left p {
    font-size: 17px;
  }
  body .expertise__left--military {
    padding: 50px 15px;
  }
  body .services p {
    font-size: 18px;
  }
  body .services__text {
    padding-bottom: 50px;
  }
  body .services__item {
    text-align: center;
    margin-bottom: 50px;
  }
  body .services__item picture {
    height: 100px;
  }
  body .read__title {
    font-size: 17px;
    line-height: 30px;
  }
  body .footer .social {
    text-align: left;
  }
  body .footer .social .row {
    justify-content: initial;
  }
  body .footer .social ul li {
    display: block;
    padding: 15px 0px;
  }
  body .footer .social__top {
    margin-bottom: 30px;
  }
  body .footer .copyright .row {
    justify-content: flex-end;
  }
  body .footer .copyright__logo {
    display: none;
  }
  body .footer .copyright__text {
    text-align: left;
  }
  .iframe {
    display: block;
    width: 87vw;
    height: 130vw;
    max-width: 800px;
    max-height: 800px;
    margin: 1em auto;
  }
}
@media (max-width: 380px) {
  body .statistic .fuel__type {
    display: inline-block;
    width: 100%;
  }
  body .post {
    height: 100%;
  }
  .iframe {
    display: block;
    width: 85vw;
    height: 90vh;
    margin: 1em auto;
    margin-bottom: 10em;
  }
}
