@font-face {
    font-family: "Helvetica-Black";
    src: url(./assets/fonts/Helvetica93-ExtendedBlack.woff) format('woff');
    font-display: fallback;
  }
  
  @font-face {
    font-family: "Helvetica-Bold";
    src: url(./assets/fonts/Helvetica73-ExtendedBold.woff) format('woff');
    font-display: fallback;
  }
  
  @font-face {
    font-family: "Helvetica-Regular";
    src: url(./assets/fonts/Helvetica53-Extended.woff) format('woff');
    font-display: fallback;
  }
  
  @font-face {
    font-family: "Helvetica-Medium";
    src: url(./assets/fonts/Helvetica63-ExtendedMedium.woff) format('woff');
    font-display: swap;
  }
  
  @font-face {
    font-family: "Helvetica-Light";
    src: url(./assets/fonts/Helvetica43-ExtendedLight.woff) format('woff');
    font-display: fallback;
  }